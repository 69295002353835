<template>
  <div class="fixed-widgets">
    <a-popover :getPopupContainer="trigger => trigger.parentNode">
      <template #content>
        <img style="width: 130px;" src="@/assets/image/public/img-wechat.jpg" alt="" />
        <p style="text-align: center; margin: 2px 0 0;">{{ $t('public.business_consulting_please_contact') }}</p>
      </template>
      <div class="fixed-widgets-icon">
        <WechatOutlined style="font-size: 32px; color: rgba(7, 193, 96, 1);" />
      </div>
    </a-popover>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Popover } from 'ant-design-vue';

export default defineComponent({
  components: {
    APopover: Popover
  },
  setup () {
    return {}
  }
})
</script>

<style lang="less" scoped>
.fixed-widgets {
  position: fixed;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2147483640;
  &-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
}
</style>