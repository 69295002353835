<template>
<div>
    <a-row
      justify="center"
      align="middle"
      class="pt-4"
    >
      <a-col
        :xl="15"
        :lg="19"
        :md="21"
        :sm="23"
        :xs="23"
      >
<p class="text-center"><b><span>隐私政策</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>赛易（深圳）供应链科技有限公司（“<b>我们</b>”、“<b>赛易科技</b>”或“<b>平台</b>”）非常重视用户（“<b>您</b>”）的隐私和个人信息保护，也深知个人信息对您的重要性。我们将根据法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。在您使用平台用户端提供的服务时，平台将按照本隐私权政策收集、使用、共享、存储和保护您的个人信息。本隐私权政策包含了我们收集、使用、共享、存储和保护您的个人信息的条款，我们希望通过本隐私权政策向您清晰地介绍平台对您个人信息的处理方式。<b>我们建议，在使用平台用户端各项服务前，请您务必仔细、完整并透彻阅读及理解本隐私权政策，以帮助您了解保护自己个人信息的方式。</b></span></p>

<p><span>&nbsp;</span></p>

<p><span>在阅读本隐私权政策的过程中，如果您对本隐私权政策有任何疑问，您可以通过平台的在线客服、或发送邮件至【<span>cooperation@saleasy.com</span>】等方式与我们联系。如果您不同意本隐私权政策或其中任何内容，您可以拒绝使用平台服务；当您使用平台服务时，如果您拒绝提供您的相关个人信息，您可能无法正常使用我们的服务。</span></p>

<p><span>&nbsp;</span></p>

<p><span>本隐私权政策将帮助您了解以下内容：</span></p>

<p><span>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>平台如何收集您的个人信息</span></p>

<p><span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>平台如何使用您的个人信息</span></p>

<p><span>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookies</span><span>和同类技术的使用</span></p>

<p><span>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>平台如何共享、转让、披露您的个人信息</span></p>

<p><span>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>平台如何存储您的个人信息</span></p>

<p><span>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>平台如何保护您的个人信息</span></p>

<p><span>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>您如何管理个人信息</span></p>

<p><span>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>未成年人的个人信息保护</span></p>

<p><span>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>隐私权政策的变更或修改</span></p>

<p><span>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>如何联系平台</span></p>

<p><span>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>定义</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>一、<span>&nbsp;&nbsp;&nbsp;</span>平台如何收集您的个人信息</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>我们仅会出于以下目的，收集和使用您的个人信息，如您提供的个人信息不是本人信息，请保证您已经获得相关个人的授权。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>平台官网</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>1</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><b>创建账号。</b>当您创建平台账号时，您需要向我们提供您的客户类型（如，大陆客户、香港客户、台湾客户、澳门客户、澳大利亚客户、其他海外客户）、联系人姓名、电子邮箱、手机号码和平台账号密码。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><b>身份与资质认证。</b>当您进行平台账号身份核验或实名认证时，您根据页面提示的不同的身份核验或实名认证方式，需要向我们提供您的<b><u>面部照片、身份证副本及其所载身份信息（包括您的姓名、面部照片、身份证号码）</u></b>。如您继续进行平台账号资质认证，除前述信息外，您可能还需要根据不同的资质认证要求填写及<span>/</span>或提交您所在工作单位信息、工作电子邮箱、职位信息、营业执照扫描件。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><b>相关服务。</b>当您开通赛易物流服务、赛易海外仓服务、赛易金融服务时，您可能需要向我们提供的个人信息有：您的开户类型（个人、企业）、地址证明文件、公司地址、主联系人的姓名、手机号码、职务和微信、紧急联系人姓名和联系电话。</span></p>

<p><span>&nbsp;</span></p>

<p><span>4</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><b>寄件服务。</b>如果您是寄件人，为了完成您所选择的服务或达成的交易，您需要向我们提供包裹信息（包括包裹所含商品<span>/</span>物品名称、商品<span>/</span>物品价值<span>/</span>价格）、寄件人信息（包括寄件人姓名、电话号码、电子邮箱、地址）以及收件人信息（包括收件人姓名、电话号码、电子邮箱、地址）。</span></p>

<p><span>&nbsp;</span></p>

<p><span>5</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><b>订单结算</b>。为了完成您所选择的服务或对达成的交易费用进行结算，当您选择线上付款时，您需要向我们提供您的付款渠道、货币类型、<b><u>付款账户</u></b>、<b><u>付款金额</u></b>以及付款人名称，如果您填写有备注的，我们还会收集您填写的备注信息。如果您通过他人代为付款，您还需要向我们提供代付人的名称。当您选择线下付款时，您需要向我们提供您的汇款时间、收款渠道、货币类型、<b><u>付款账户、汇款金额</u></b>以及</span><b><u><span>银行转账凭证</span></u></b><span>，</span><span>如果您填写有备注的，我们还会收集您填写的备注信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>6</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><b>消息订阅。</b>当您管理消息订阅时，您需要向我们提供订阅人的姓名、职务、电子邮箱以及手机号码。</span></p>

<p><span>&nbsp;</span></p>

<p><span>7</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><b>沟通与咨询。</b>当您使用我们的在线客服功能时，您需要向我们提供您对问题的描述、相关问题、您的邮箱、姓名以及咨询问题类型，以便于我们对您提出的咨询进行反馈。</span></p>

<p><span>&nbsp;</span></p>

<p><span>8</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><b>招聘服务。</b>如果您通过我们官网的招聘入口进行应聘，我们会收集您的手机号，您也可以通过微博或<span>QQ</span>账号直接登录。如果您决定申请我们的职位，您可以直接向我们提供您的简历，或者向我们提供您的姓名、性别、出生日期、邮箱、手机号、工作年限、期望从事行业、期望从事职务、期望工作城市、<b><u>照片</u></b>、现从事行业（选填）、现从事执业（选填）、现工作城市（选填）、现月薪（税前）（选填）、期望月薪（税前）（选填）、到岗时间（选填）、<b><u>教育背景、工作经验</u></b>、语言能力（选填），以帮助我们决定是否录用您。如果我们不决定录用您，我们将会删除您提供的信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>9</span><span>、<span>&nbsp;</span><b>浏览页面。</b>在您使用平台服务时，我们自动接收并记录的您的浏览器和计算机上的信息，包括您的<span>IP</span>地址、浏览器的类型和版本。。</span></p>

<p><span>&nbsp;</span></p>

<p><span>10</span><span>、<b>调查和统计。</b>我们可能收集与您沟通的相关信息以及您对我们服务的反馈，包括您与平台的客户服务团队联系时您提供的相关信息、您参与问卷调查时向平台发送的问卷答复信息、以及其他互动时我们收集的相关信息，该等信息将有利于我们更好的了解您的需求和建议。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>11</span></b><b><span>、<span>&nbsp;</span>安全风险识别。</span></b><span>为了提高您使用平台用户端提供的服务的安全性，为了更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过收集您的<span>IP</span>地址、浏览器类型和版本信息来判断您账号的风险，并可能会记录一些我们认为有风险的<span>URL</span>。</span></p>

<p><b><span>微信公众号</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>1</span><span>、<span>&nbsp;</span><b>关注微信公众号。</b>当您关注并使用我们的微信公众号时，我们会收集您的微信头像、昵称信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;</span><b>业务咨询。</b>当您在微信公众号进行业务咨询时，您需要填写简单的问卷调查，并提交您的手机号码，方便我们与您取得联系。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;</span><b>在线客服。</b>当您在微信公众号进行业务咨询时，在线客服会记录您提出的问题以及与您的沟通记录。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>其他渠道</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>1</span><span>、<span>&nbsp;</span>为了完成您所选择的服务或达成的交易，并实时跟踪您的订单进展，平台关联方或平台物流合作伙伴会向我们共享您的个人信息，包括交易订单所含包裹信息（包括包裹所含商品<span>/</span>物品名称、商品<span>/</span>物品价值<span>/</span>价格），寄件人信息（包括寄件人姓名、电话号码、电子邮箱、地址），收件人信息（包括收件人姓名、电话号码、电子邮箱、地址）。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>二、<span>&nbsp;&nbsp;&nbsp;</span>平台如何使用您的个人信息</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>1</span><span>、<span>&nbsp;</span>为履行与您之间的合同。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;</span>向您提供您使用的各项服务。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;</span>履行适用法律法规要求的义务，配合法院和监管机关的命令、指令等。</span></p>

<p><span>&nbsp;</span></p>

<p><span>4</span><span>、<span>&nbsp;</span>为改善您使用的各项服务，向您提供更符合您个性化需求的信息展示、搜索及服务。平台会根据您的订单信息，基于特征标签进行间接人群画像，并展示、推送信息和可能的商业广告。如果您不想接收平台给您发送的商业广告，您可以通过提示方式退订或通过本隐私权政策的“如何联系平台”的方式联系我们进行退订。</span></p>

<p><span>&nbsp;</span></p>

<p><span>5</span><span>、<span>&nbsp;</span>为提高您使用平台、平台关联方、平台物流合作伙伴提供的服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或平台用户端相关协议规则的情况，我们可能会收集您的<span>IP</span>地址，以屏蔽可疑<span>IP</span>地址对平台的访问。</span></p>

<p><span>&nbsp;</span></p>

<p><span>6</span><span>、<span>&nbsp;</span>我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善我们户端的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务。例如：使用匿名数据进行机器学习或模型算法训练。</span></p>

<p><span>&nbsp;</span></p>

<p><span>7</span><span>、<span>&nbsp;</span>如果我们将特定信息用于本隐私权政策未载明的其他用途，或者将基于目的收集而来的信息用于其他目的时，会事先征求您的同意。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>三、<span>&nbsp;&nbsp;&nbsp;Cookies</span>和同类技术的使用</span></b></p>

<p><b><span>&nbsp;</span></b></p>

<p><span>（一）<span>&nbsp;Cookies</span>的使用</span></p>

<p><span>&nbsp;</span></p>

<p><span>1</span><span>、<span>&nbsp;</span>为使您获得更轻松的访问体验，您访问平台用户端或使用服务时，我们会在您的计算机或移动设备上发送一个或多个名为<span>Cookies</span>的小数据文件，指定给您的<span>Cookies&nbsp;</span>是唯一的，它只能被将<span>Cookies</span>发布给您的域中的<span>Web</span>服务器读取。我们向您发送<span>Cookies</span>是为了简化您重复输入账号信息的步骤、帮助判断您的登录状态以及账户或数据安全。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;</span>我们不会将<span>&nbsp;Cookies&nbsp;</span>用于本隐私权政策所述目的之外的任何用途。您可根据自己的偏好管理或删除<span>&nbsp;Cookies</span>。您可以清除计算机上保存的所有<span>&nbsp;Cookies</span>，大部分网络浏览器会自动接受<span>Cookies</span>，但您通常可根据自己的需要来修改浏览器的设置以拒绝<span>&nbsp;Cookies</span>；另外，您也可以清除软件内保存的所有<span>Cookies</span>。但如果您这么做，您可能需要在每一次访问平台用户端时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（二）<span>&nbsp;</span>网络<span>Beacon</span>和同类技术的使用</span></p>

<p><span>&nbsp;</span></p>

<p><span>除<span>Cookies&nbsp;</span>外，我们还会在平台用户端上使用网络<span>Beacon</span>等其他同类技术。我们的网页上常会包含一些电子图像（称为<span>"</span>单像素<span>" GIF&nbsp;</span>文件或<span>&nbsp;"</span>网络<span>&nbsp;Beacon"</span>）。我们使用网络<span>Beacon</span>的方式即：通过在平台用户段使用网络<span>Beacon</span>，计算用户访问数量、停留时间。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（三）<span>&nbsp;</span>第三方代码、插件</span></p>

<p><span>&nbsp;</span></p>

<p><span>为了向您提供更好的服务，我们可能会通过第三方代码或<span>SDK</span>来收集您的个人信息，以帮助我们为您提供更加全面的服务。我们将尽力控制和保护对您个人信息的收集。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>四、<span>&nbsp;&nbsp;&nbsp;</span>平台如何共享、转让、披露您的个人信息</span></b></p>

<p><b><span>&nbsp;</span></b></p>

<p><span>1</span><span>、<span>&nbsp;</span>我们只会在下列情况下才将您的个人信息与第三方共享<span>:</span></span></p>

<p><span>&nbsp;</span></p>

<p><span>（<span>1</span>）<span>&nbsp;</span>事先获得您的同意或授权情况下共享您的个人信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（<span>2</span>）<span>&nbsp;</span>根据法律法规的规定或行政或司法机构的要求共享您的个人信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（<span>3</span>）<span>&nbsp;</span>向平台关联方共享您的个人信息。为便于我们基于平台向您提供服务，推荐您可能感兴趣的信息，识别平台账号异常，保护平台关联方或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与平台关联方共享。我们只会共享必要的个人信息，且受本隐私权政策中所声明目的的约束，如果我们共享您的个人信息或平台关联方改变个人信息的使用及处理目的，将再次征求您的授权同意。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（<span>4</span>）<span>&nbsp;</span>为了便于向您提供更好的物流服务，我们可能委托授权我们的物流合作伙伴为您提供物流服务或代表我们履行物流服务职能，包括但不限于寄件、查件、派件服务，我们会向可信赖的物流合作伙伴共享您的个人信息，并严格限制我们的物流合作伙伴对您的个人信息的使用，让他们根据我们的指示并遵循本隐私权政策以及其他任何相应的保密和安全措施来为我们处理您的个人信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;</span>我们不会将您的个人信息转让给任何公司、组织和个人，但如平台发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私权政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。在转让您的个人信息之前，我们将向您发送适当的通知，告知您接收方的名称或者姓名和联系方式，并确保您的个人信息将以符合我们隐私权政策的方式得到保护。接收方变更处理目的、处理方式的，我们将会要求相关数据接收方再次获得您的同意。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;</span>我们仅会在以下情况下，披露您的个人信息：</span></p>

<p><span>&nbsp;</span></p>

<p><span>（<span>1</span>）<span>&nbsp;</span>在获得您单独同意或基于您的主动选择，我们可能会披露您的个人信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（<span>2</span>）<span>&nbsp;</span>为应对突发公共卫生事件，或为保护您、平台用户、平台或公众的人身财产安全免遭侵害，有必要披露您的个人信息，我们将在适用的法律、法规允许的范围内，披露您的个人信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>4</span><span>、<span>&nbsp;</span>根据适用法律法规的规定，若我们对您的个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、披露无需另行向您通知并征得您的同意。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>五、<span>&nbsp;&nbsp;&nbsp;</span>平台如何存储您的个人信息</span></b></p>

<p><b><span>&nbsp;</span></b></p>

<p><span>1</span><span>、<span>&nbsp;</span>除非适用法律法规有强制的存留期限要求，我们仅为达成本隐私权政策所述之目的所需的期限内保留您的个人信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;</span>除非您所在国家<span>/</span>地区或您使用我们的服务时所在国家<span>/</span>地区或您访问平台时所在国家<span>/</span>地区或我们收集信息所在国家<span>/</span>地区有明确法律禁止，或获得您明确的授权，或您的个人主动行为，我们在中华人民共和国境内合法收集的有关您的个人信息将保存在中华人民共和国境内。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;</span>由于我们通过境内外的资源和仓库向您提供服务，这意味着，您的个人信息可能会被转移到您指定的海外仓库（包括但不限于美国、英国、欧盟、墨西哥等），或者受到来自这些海外仓库的访问。我们会严格遵守相关法律法规对数据跨境传输的各项要求并采取相应措施，例如对个人信息跨境传输采取定期安全个人信息保护影响评估、认证或签署标准合同，并根据适用法律和评估结果决定是否继续跨境传输您的个人信息，并在跨境数据转移之前实施数据脱敏、去标识化等安全举措。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>六、<span>&nbsp;&nbsp;&nbsp;</span>平台如何保护您的个人信息</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>1</span><span>、<span>&nbsp;</span>为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或丢失，包括但不限于<span>HTTPS</span>协议进行传输加密、<span>SSL</span>、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施，进行安全和隐私保护培训。我们会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄漏、毁损或丢失。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;</span>您的账号均有安全保护功能，请妥善保管您的账号及密码信息。我们将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完善的安全措施”，我们建议您采取积极措施，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;</span>在使用我们的服务时，您不可避免的要向经营快递业务的企业或包裹寄<span>/</span>收件人或您委托的包裹实际寄<span>/</span>收件人披露自己的个人信息，如：联络方式或邮政地址信息，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是您的账号及密码发生泄露，请您立即联络我们以便采取相应措施。</span></p>

<p><span>&nbsp;</span></p>

<p><span>4</span><span>、<span>&nbsp;</span>在获悉不幸发生个人信息安全事件后，我们将按照法律法规的要求向您或监管部门报告：发生或者可能发生个人信息泄露、篡改、丢失的信息种类、原因和可能造成的危害，我们采取的补救措施和个人可以采取的减轻危害的措施，以及相关处理负责人的联系方式。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>七、<span>&nbsp;&nbsp;&nbsp;</span>您如何管理个人信息</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>（一）<span>&nbsp;&nbsp;&nbsp;</span>您的个人信息查询、复制和更新</span></p>

<p><span>&nbsp;</span></p>

<p><span>1</span><span>、<span>&nbsp;</span>若您需要查询您平台账号上的个人信息，您可以直接登陆平台用户端，查看“个人中心”下的“我的资料”，以查询您的姓名、电子邮箱、手机号码、平台账号；您还可以通过查看“服务与合同”查询您在平台上开通的服务及签署过的协议。如果您无法查询您的个人信息，您可以通过本隐私权政策的“如何联系平台”的方式联系我们。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;</span>若需要获取我们收集的您的个人信息副本，您可以通过本隐私权政策的“如何联系平台”的方式联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供您的个人信息副本。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;</span>若您在注册账号或使用平台服务过程中提供的信息有变化，您可以直接登陆平台，查看“我的资料”更新您的个人信息。如果您无法更新您的个人信息，您可以通过本隐私权政策的“如何联系平台”的方式联系我们。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（二）<span>&nbsp;&nbsp;&nbsp;</span>您的个人信息删除</span></p>

<p><span>&nbsp;</span></p>

<p><span>1</span><span>、<span>&nbsp;</span>若发生如下任一情形，您可以通过本隐私权政策的“如何联系平台”的方式向我们提出删除您的个人信息的请求。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（<span>1</span>）<span>&nbsp;</span>如果我们处理个人信息的行为违反法律法规；</span></p>

<p><span>（<span>2</span>）<span>&nbsp;</span>如果我们收集、使用您的个人信息，却未征得您的明确同意；</span></p>

<p><span>（<span>3</span>）<span>&nbsp;</span>如果我们处理个人信息的行为严重违反了与您的约定；</span></p>

<p><span>（<span>4</span>）<span>&nbsp;</span>如果您不再使用我们的服务，或您的平台账号已按照平台账号注销流程注销的；</span></p>

<p><span>（<span>5</span>）<span>&nbsp;</span>如果平台永久不再为您提供产品或服务；</span></p>

<p><span>（<span>6</span>）<span>&nbsp;</span>如果我们处理您个人信息的目的已实现、无法实现或者为实现处理目的不再必要。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;</span>如果我们决定响应您的删除请求，我们还将同时尽可能通知从平台处获得您的个人信息的第三方主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;</span>当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名，并停止除存储和采取必要的安全保护措施之外的处理行为。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（三）<span>&nbsp;&nbsp;&nbsp;</span>撤销同意权</span></p>

<p><span>&nbsp;</span></p>

<p><span>若您希望撤回对我们基于您的同意收集的个人信息使用的同意，您可以通过删除信息、调整浏览器设置等方式变更您的授权范围，您也可以通过本隐私权政策的“如何联系平台”的方式与我们联络。此外，您可以通过注销帐户的方式，撤回我们继续收集您个人信息的全部授权。当您变更或撤回您的授权同意后，我们可能无法继续为您提供该等授权所对应的服务，也不再处理您相应的个人信息。但您变更或撤回同意或授权的决定，不会影响此前我们基于您的同意或授权而开展的个人信息处理。</span></p>

<p><span>&nbsp;</span></p>

<p><span>（四）<span>&nbsp;&nbsp;&nbsp;</span>注销账户权</span></p>

<p><span>&nbsp;</span></p>

<p><span>若您希望注销您的账户，请根据通过本隐私权政策的“如何联系平台”的方式与我们联络，我们将采取合理的步骤将您的账户注销，我们将根据您的要求或法律法规的要求删除您的个人信息。除非我们因法律原因而需要保存该等资料，我们将仅限于法律法规所规定的范围内进行处理，不会将其用于日常业务活动中。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>八、<span>&nbsp;&nbsp;&nbsp;</span>未成年人的个人信息保护</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>一般来说，我们不会向未满<span>14</span>周岁的未成年人提供平台服务，也不会主动收集和处理<span>14</span>周岁以下的儿童的个人信息。未成年人请不要注册账号或发送自己的姓名、住址、电话、邮件地址等个人信息给我们。但是由于技术原因，在某些情况下，我们无法识别用户的年龄。如果您认为我们可能不当地持有来自于或关于未成年人的信息，请通过本隐私权政策的“如何联系平台”的方式联系我们。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>九、<span>&nbsp;&nbsp;&nbsp;</span>隐私权政策的变更或修改</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>1.&nbsp;</span><span>为给您提供更好的服务以及随着平台业务的发展，本隐私权政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私权政策所应享有的权利。我们会通过在平台上发出更新版本并在生效前通过公告或以其他适当方式提醒您相关内容的更新。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2.&nbsp;</span><span>对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私权政策的具体变更内容）。本隐私权政策所指的重大变更包括但不限于：</span></p>

<p><span>&nbsp;</span></p>

<p><span>（<span>1</span>）<span>&nbsp;</span>我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span></p>

<p><span>（<span>2</span>）<span>&nbsp;</span>我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span></p>

<p><span>（<span>3</span>）<span>&nbsp;</span>个人信息共享、转让或公开披露的主要对象发生变化；</span></p>

<p><span>（<span>4</span>）<span>&nbsp;</span>您参与个人信息处理方面的权利及其行使方式发生重大变化；</span></p>

<p><span>（<span>5</span>）<span>&nbsp;</span>我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span></p>

<p><span>（<span>6</span>）<span>&nbsp;</span>个人信息安全影响评估报告表明存在高风险时。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>十、<span>&nbsp;</span>如何联系平台</span></b></p>

<p><b><span>&nbsp;</span></b></p>

<p><span>1</span><span>、<span>&nbsp;&nbsp;</span>如您对本隐私权政策或您个人信息的相关事宜有任何问题、意见或建议，您可以通过平台的在线客服、或发送邮件至<span>cooperation@saleasy.com </span>等多种方式与我们联系。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>我们设置了个人信息保护负责人，您可以通过<span>cooperation@saleasy.com</span>方式联系我们的个人信息保护负责人。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>一般情况下，收到您的反馈后，我们将在十五日内回复。</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>十一、<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>定义</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>1</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>平台：指平台官网（域名为【<span>https://www.saleasy.com</span>】）和赛易供应链科技微信公众号（“<b>微信公众号</b>”）。</span></p>

<p><span>&nbsp;</span></p>

<p><span>2</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>个人信息：指以电子或者其它方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</span></p>

<p><span>&nbsp;</span></p>

<p><span>3</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>个人敏感信息：一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括但不限于个人财产信息、交易和消费记录、浏览记录等，我们将个人敏感信息用<b><u>下划线</u></b>的方式标识，以提请您的注意。</span></p>

<p><span>&nbsp;</span></p>

<p><span>4</span><span>、<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>除另有约定外，本隐私权政策所用定义与《赛易科技用户注册服务协议》中的定义具有相同的涵义。</span></p>

<p><span>&nbsp;</span></p>
  </a-col>
 </a-row>
 <WechatDot />
</div>
</template>

<script>
import { Row, Col, Space } from "ant-design-vue";
import { defineComponent, reactive, ref, toRefs } from "vue";
import WechatDot from './components/WechatDot.vue';

export default defineComponent({
    name:"public_privacy",
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    WechatDot,
  },
});
</script>